













import Vue from 'vue';
import TpImage from '@online/vue-library/components/TpImage.vue';

export default Vue.extend({
  name: 'ProductMood',
  components: {
    TpImage,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    alignment: {
      type: String,
      default: "left",
    }
  },
});
