import Vue from 'vue'
import Router from 'vue-router'
import Index from './views/Index.vue'
import Examples from './views/Examples.vue'
import SearchResult from './views/SearchResult.vue'
import Picture from './views/Picture.vue'
import Zoom from './views/Zoom.vue'
import ExpansionPanelScroll from './views/ExpansionPanelScroll.vue';
import CategoryPage from './views/CategoryPage.vue';
import UserLogin from './views/UserLogin.vue';

Vue.use(Router);

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index
    },
    {
      path: '/examples',
      name: 'Examples',
      component: Examples
    },
    {
      path: '/SearchResult',
      name: 'Search Result',
      component: SearchResult
    },
    {
      path: '/Picture',
      name: 'Picture',
      component: Picture
    },
    {
      path: '/Zoom',
      name: 'Zoom',
      component: Zoom
    },
    {
      path: '/ExpansionPanelScroll',
      name: 'ExpansionPanelScroll',
      component: ExpansionPanelScroll
    },
    {
      path: '/CategoryPage',
      name: 'CategoryPage',
      component: CategoryPage
    },
    {
      path: '/UserLogin',
      name: 'UserLogin',
      component: UserLogin
    }

  ]
})
