

































































































































import Vue from 'vue';


import TpImage from '@online/vue-library/components/TpImage.vue';
import TpButton from '@online/vue-library/components/TpButton.vue';
import TpIcon from '@online/vue-library/components/TpIcon.vue';
import TpSearchSuggest from '@online/vue-library/components/TpSearchSuggest.vue';
import TpRating from '@online/vue-library/components/TpRating.vue';
import TpProductVariant from '@online/vue-library/components/TpProductVariant.vue';
import TpExpansionPanelGroup from '@online/vue-library/components/TpExpansionPanelGroup.vue';
import TpExpansionPanel from '@online/vue-library/components/TpExpansionPanel.vue';
import TpLoader from '@online/vue-library/components/TpLoader.vue';
import TpAnimatedIcon from '@online/vue-library/components/TpAnimatedIcon.vue'

export default Vue.extend({
  components: {
    TpImage,
    TpButton,
    TpIcon,
    TpSearchSuggest,
    TpRating,
    TpProductVariant,
    TpExpansionPanelGroup,
    TpExpansionPanel,
    TpLoader,
    TpAnimatedIcon
  },
  data() {
    return {
      wusel: "",
      active: false
    }
  },
  computed: {},
  mounted: function () {
  },
  methods: {
    changeVariant(id: number) {
      console.log(id);
    },
    searchSuggestInput(e: string) {
      console.log(e);
    },
    searchSuggestSubmit(e: string) {
      console.log(e);
    },
    updateSearchInput() {
      this.$data.wusel = 'Test neu';
    }
  }
});
