
















import Vue from 'vue';
import TpImage from '@online/vue-library/components/TpImage.vue';
import Breakpoints from '@online/vue-global/variables/breakpoints.json'

export default Vue.extend({
  components: {
    TpImage
  },
  data() {
    return {
      breakpoints: Breakpoints
    }
  },
  computed: {},
  mounted: function () {
  },
  methods: {
    getPictureSources() {
      const sources = './product-image-xs.png '+ this.$data.breakpoints['xs'] +'w,' +
          ' ./product-image-s.png '+ this.$data.breakpoints['s'] +'w,' +
          ' ./product-image-m.png '+ this.$data.breakpoints['m'] +'w,' +
          ' ./product-image-l.png '+ this.$data.breakpoints['l'] +'w,' +
          ' ./product-image-xl.png '+ this.$data.breakpoints['xl'] +'w,' +
          ' ./product-image-xxl.png '+ this.$data.breakpoints['xxl'] +'w,' +
          ' ./product-image-3xl.png '+ this.$data.breakpoints['3xl'] +'w,' +
          ' ./product-image-4xl.png '+ this.$data.breakpoints['4xl'] +'w';
      const srcset = {
        srcset: ""
      }
      srcset.srcset = sources;
      const pictureSources = [];
      pictureSources.push(srcset);
      return pictureSources;
    }
  }
});
