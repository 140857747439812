















import Vue from 'vue';
import Breakpoints from '@online/vue-global/variables/breakpoints.json'

export default Vue.extend({
  components: {},
  data() {
    return {
      breakpoints: Breakpoints,

      active: false,
      currentX: 0,
      currentY: 0,
      initialX: 0,
      initialY: 0,
      xOffset: 0,
      yOffset: 0,
    }
  },
  computed: {},
  mounted: function () {

  },
  methods: {
    dragStart(event: TouchEvent & MouseEvent) {

      if (event.type === "touchstart") {

        //console.log(event.touches);

        this.$data.initialX = event.touches[0].clientX - this.$data.xOffset;
        this.$data.initialY = event.touches[0].clientY - this.$data.yOffset;
      } else {
        this.$data.initialX = event.clientX - this.$data.xOffset;
        this.$data.initialY = event.clientY - this.$data.yOffset;
      }

      if ((event.target as HTMLElement).parentNode === this.$refs.item) {
        this.$data.active = true;
      }
    },

    dragEnd() {
      this.$data.initialX = this.$data.currentX;
      this.$data.initialY = this.$data.currentY;

      this.$data.active = false;
    },

    drag(event: TouchEvent & MouseEvent) {
      if (this.$data.active) {

        event.preventDefault();

        if (event.type === "touchmove") {
          this.$data.currentX = event.touches[0].clientX - this.$data.initialX;
          this.$data.currentY = event.touches[0].clientY - this.$data.initialY;
        } else {
          this.$data.currentX = event.clientX - this.$data.initialX;
          this.$data.currentY = event.clientY - this.$data.initialY;
        }

        this.$data.xOffset = this.$data.currentX;
        this.$data.yOffset = this.$data.currentY;

        //console.log(this.$refs.item);

        this.setTranslate(this.$data.currentX, this.$data.currentY, this.$refs.item);
      }
    },

    setTranslate(xPos: number, yPos: number, el: any) {
      window.requestAnimationFrame(function() {
        el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
      });
    },

  }
});
