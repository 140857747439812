



















import Vue from 'vue';

import TpSlider from '@online/vue-library/components/TpSlider.vue';
import TpSliderItem from '@online/vue-library/components/TpSliderItem.vue';
import ProductTile from '@/components/ProductTile.vue';

export default Vue.extend({
  components: {
    TpSlider,
    TpSliderItem,
    ProductTile
  },
  data() {
    return {
      breakpoint: 'xs',
      sliderStyle: '',
      products: [
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        },
        {
          title: 'Product'
        }
      ],
      slides: [
        {
          title: '1'
        },
        {
          title: '2'
        },
        {
          title: '3'
        },
        {
          title: '4'
        },
        {
          title: '5'
        },
        {
          title: '6'
        },
        {
          title: '7'
        },
        {
          title: '8'
        },
        {
          title: '9'
        },
        {
          title: 'END'
        }
      ]
    }
  },
  computed: {},
  mounted: function () {
  },
  methods: {
    onResize(sizes: { breakpoint: string }) {
      //console.log(sizes);
      const wusel = (this.$refs.page as HTMLDivElement)
      //console.log(wusel);
      //console.log(wusel.style);

      const pl = (getComputedStyle(wusel).paddingLeft).replace('px', '');
      const plx2 = String(Number(pl)*2);
      this.$data.sliderStyle = 'margin-left: -'+ pl +'px; margin-right: -'+ pl +'px; width: calc(100% + '+ plx2 +'px)';

      this.$data.breakpoint = sizes.breakpoint;
    },
    getOrderClass(index: number) {
      let orderClass = 'tp-col-order-1';
      if ((this.$data.breakpoint === 'xs' || this.$data.breakpoint === 's' || this.$data.breakpoint === 'm') && index > 3) {
        orderClass = 'tp-col-order-3';
      } else if ((this.$data.breakpoint === 'l' || this.$data.breakpoint === 'xl') && index > 5) {
        orderClass = 'tp-col-order-3';
      } else if ((this.$data.breakpoint === 'xxl' || this.$data.breakpoint === '3xl' || this.$data.breakpoint === '4xl') && index > 7) {
        orderClass = 'tp-col-order-3';
      }
      return orderClass;
    }
  }
});
