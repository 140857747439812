













































































































































import Vue from 'vue';

import TpOffCanvasFlyOut from '@online/vue-library/components/TpOffCanvasFlyOut.vue';
import TpExpansionPanelGroup from '@online/vue-library/components/TpExpansionPanelGroup.vue';
import TpExpansionPanel from '@online/vue-library/components/TpExpansionPanel.vue';

export default Vue.extend({
  components: {
    TpOffCanvasFlyOut,
    TpExpansionPanelGroup,
    TpExpansionPanel
  },
  props: {
    value: {
      type: String
    },
  },
  data() {
    return {
      openPanels: [],
      actualSelectedPanel: undefined as unknown as number,
      animationDelay: 0,
    }
  },
  computed: {},
  mounted: function () {},
  methods: {
    selectedPanels: function (panels: []) {

      // a panel was opened
      if(panels.length > this.$data.openPanels.length) {
        this.$data.actualSelectedPanel = panels[panels.length - 1];

        // short delay to check if the panel is fully in view
        clearTimeout(this.$data.animationDelay);
        this.$data.animationDelay = setTimeout(this.scrollIntoView, 300);

      }
      // store open panels
      this.$data.openPanels = panels;

    },
    scrollIntoView: function () {

      // get position data of the fly out content wrapper
      const flyOutContent = (this.$refs["flyOut"] as Vue).$el.getElementsByClassName('tp-off-canvas-fly-out-content')[0];
      const flyOut = {
        height: flyOutContent.clientHeight,
        top: flyOutContent.getBoundingClientRect().top,
        scroll: flyOutContent.scrollTop
      }

      // get position data of the selected panel
      const selectedPanel = (this.$refs["panel" + this.$data.actualSelectedPanel] as Vue)?.$el;
      const panel = {
        height: selectedPanel.clientHeight,
        top: selectedPanel.getBoundingClientRect().top
      }

      let diff: number;
      if(panel.height > flyOut.height) {
        // panel is higher as the fly out - calculate the scroll position to the top of the panel
        diff = panel.top - flyOut.top;
      } else {
        // calculate the scroll position to the bottom of the panel
        diff = panel.top + panel.height - flyOut.top - flyOut.height;
      }

      if(diff > 0) {
        let scrollTarget = flyOut.scroll + diff;
        flyOutContent.scroll({ top: scrollTarget, behavior: 'smooth' });
      }
    },
  }
});
