









import Vue from 'vue';

import UserLoginIcon from '@/components/UserLoginIcon.vue';

export default Vue.extend({
  components: {
    UserLoginIcon,
  },
  data() {
    return {
      login: false,
    };
  },
});
