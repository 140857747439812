

























import {
  defineComponent,
} from "@vue/composition-api";

export default defineComponent({
  name: 'UserLoginIcon',
  props: {
    /**
     * Show user login icon active state.
     * @property [true, false]
     */
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
  }
});
