




























import Vue from 'vue';

import TpImage from '@online/vue-library/components/TpImage.vue';
import TpPrice from '@online/vue-library/components/TpPrice.vue';
import TpIconButton from '@online/vue-library/components/TpIconButton.vue';
import TpIcon from '@online/vue-library/components/TpIcon.vue';

export default Vue.extend({
  name: 'ProductTile',
  components: {
    TpImage,
    TpPrice,
    TpIconButton,
    TpIcon
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
});
