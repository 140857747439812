











import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  data() {
    return {
    }
  },
  computed: {},
  mounted: function () {
  },
  methods: {
  }
});
