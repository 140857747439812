





























import Vue from 'vue';
import ProductTile from '@/components/ProductTile.vue';
import ProductTeaser from '@/components/ProductTeaser.vue';
import ProductMood from '@/components/ProductMood.vue';

export default Vue.extend({
  components: {
    ProductTile,
    ProductTeaser,
    ProductMood
  },
  data() {
    return {
      products: [
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
          assetAlignment: "left",
          teaser: {
            alignment: "left",
            image: "tw-teaser.jpg",
          },
        },
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
          assetAlignment: "right",
          mood: {
            alignment: "right",
            image: "dummy-mood-image-2.jpg",

          },
        },
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
          assetAlignment: "left",
          mood: {
            alignment: "left",
            image: "dummy-mood-image.jpg",
          },
        },
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
        },
        {
          title: "Product",
          assetAlignment: "right",
          teaser: {
            alignment: "right",
            image: "tw-teaser.jpg",
          },
        },
        {
          title: "Product",
        },
      ],
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    getGridClass(index: number) {
      let classes = "tp-col-6 tp-col-s-4 tp-col-4xl-3";
      if (this.$data.products[index].teaser || this.$data.products[index].mood) {
        classes = "tp-col-12 tp-col-s-8  tp-col-4xl-6";
      }
      return classes;
    },
    getAlignment(index: number) {
      if (this.$data.products[index].teaser) {
        return this.$data.products[index].teaser.alignment;
      } else if (this.$data.products[index].mood) {
        return this.$data.products[index].mood.alignment;
      } else {
        return "";
      }
    },
  }
});
