





















import Vue from 'vue';
import TpImage from '@online/vue-library/components/TpImage.vue';
import TpButton from '@online/vue-library/components/TpButton.vue';
import TpIcon from '@online/vue-library/components/TpIcon.vue';

export default Vue.extend({
  name: 'ProductTeaser',
  components: {
    TpImage,
    TpButton,
    TpIcon
  },
  props: {
    alignment: {
      type: String,
      default: "left",
    }
  },
});
